import { default as account_45lookup5NRB1ERAa0Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/account-lookup.vue?macro=true";
import { default as addqo1LVeHNT7Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/add.vue?macro=true";
import { default as deletedAbTEqVWkAOMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/deleted.vue?macro=true";
import { default as _91id_93Kz3kWxFLgHMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue?macro=true";
import { default as indexmW58RDDAR6Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/index.vue?macro=true";
import { default as _91id_93s8XtRXqunyMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue?macro=true";
import { default as companiesz3Rx9WdiU0Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies.vue?macro=true";
import { default as _91id_933A5aKRCXExMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue?macro=true";
import { default as indexNb3bTj13BJMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue?macro=true";
import { default as indexPIqh9OCrrNMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/configs/index.vue?macro=true";
import { default as dashboardZQQzosrgl7Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/dashboard.vue?macro=true";
import { default as addgN4xYhbdLfMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/add.vue?macro=true";
import { default as _91id_93iGevfQoCisMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue?macro=true";
import { default as _91id_93m2kUexOIgXMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue?macro=true";
import { default as indexIa6DQL3ZDXMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/index.vue?macro=true";
import { default as _91id_93XAO4agJq7eMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/view/[id].vue?macro=true";
import { default as index5kOzs9Az6oMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/judges/index.vue?macro=true";
import { default as indexHM19tsF3tpMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue?macro=true";
import { default as indexh0zZ3H1cxzMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/email/index.vue?macro=true";
import { default as indexSpfdua1LWkMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/index.vue?macro=true";
import { default as indexIZOmLyx4AIMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue?macro=true";
import { default as indexBejNWUR0ahMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue?macro=true";
import { default as logsDLf27niOxmMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs.vue?macro=true";
import { default as adddSWw7Mt7zQMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/add.vue?macro=true";
import { default as indee_45active_45membersGF7Rtnlt0mMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue?macro=true";
import { default as indexUL5RggZ71UMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/index.vue?macro=true";
import { default as index6KgDBe7M5cMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue?macro=true";
import { default as _91withId_932MqCRRSG6bMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue?macro=true";
import { default as indextfR2cgGnjyMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/index.vue?macro=true";
import { default as _91id_931yYjIvEam3Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue?macro=true";
import { default as resend_45welcome_45emailvMN83dtHwkMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue?macro=true";
import { default as screener_45listsPeH7p9AvwiMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue?macro=true";
import { default as indexdKiRjLnLSxMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue?macro=true";
import { default as _91id_93hzMFyxFRpNMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue?macro=true";
import { default as members7hB1YPvp1QMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members.vue?macro=true";
import { default as statisticsJdutH7qr4OMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue?macro=true";
import { default as _91id_93qSCBN2PZ6wMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue?macro=true";
import { default as indexo1PYWrbmHiMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/payments/index.vue?macro=true";
import { default as addMl0XYA1WUDMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/add.vue?macro=true";
import { default as _91id_93C1Gq96CwC8Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue?macro=true";
import { default as indexv3oomIdDT2Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/index.vue?macro=true";
import { default as _91id_93KJc4gNPTbJMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue?macro=true";
import { default as promo_45codetKYiowwcMNMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code.vue?macro=true";
import { default as screenings283ipGhwO1Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/screenings.vue?macro=true";
import { default as indexQHfY1uRCGDMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/index.vue?macro=true";
import { default as inviteOI8tk1XcWpMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/invite.vue?macro=true";
import { default as _91code_93efuQtC7BlWMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue?macro=true";
import { default as ticketsznfWSuR92CMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets.vue?macro=true";
import { default as indexG2C9RfBDRKMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue?macro=true";
import { default as dashboardeu89o9yQkFMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue?macro=true";
import { default as indexJNE9yN58PqMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue?macro=true";
import { default as _91invoiceId_93kKhRrZnTkSMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue?macro=true";
import { default as addNzB6THnj1KMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue?macro=true";
import { default as multiple_45addZX9eYsHXKAMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue?macro=true";
import { default as index1vfk5JUpAaMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue?macro=true";
import { default as companiesxJlGyEbhTrMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies.vue?macro=true";
import { default as indexfhZSSfUcwHMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue?macro=true";
import { default as forgot_45passwordxwjGW0tpm0Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/forgot-password.vue?macro=true";
import { default as indexr7IKF0UPPSMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/index.vue?macro=true";
import { default as invoicesJSPLM7tIxiMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/invoices.vue?macro=true";
import { default as apply6GsZV2FkSAMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/judges/apply.vue?macro=true";
import { default as _91_46_46_46id_93pVbYnIkub5Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/addresses/[...id].vue?macro=true";
import { default as _91id_931E4NMVDFDkMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/edit/[id].vue?macro=true";
import { default as indexYdQU7yrF0FMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/membership/index.vue?macro=true";
import { default as indexKOa3Iu506cMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/signup/index.vue?macro=true";
import { default as _91_46_46_46id_93WliK3sP37pMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/view/[...id].vue?macro=true";
import { default as membersQNE8HcMSJfMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members.vue?macro=true";
import { default as indexL36YH2EGr2Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/index.vue?macro=true";
import { default as _91_46_46_46id_93T81f0bmh5zMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue?macro=true";
import { default as _91id_931PaUrNp1kJMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue?macro=true";
import { default as indexGksGhy5UeQMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue?macro=true";
import { default as _91id_938mihhgZ6aWMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/[id].vue?macro=true";
import { default as _91invoiceId_93vV47Gsf6CTMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue?macro=true";
import { default as password_45resetRnz6A4BlylMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/password-reset.vue?macro=true";
import { default as indexH618k8bOuiMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/index.vue?macro=true";
import { default as _91id_93cBPgadeClwMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/invoice/[id].vue?macro=true";
import { default as _91id_931ZvcBevkY6Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/receipt/[id].vue?macro=true";
import { default as _91id_93ubO6UkxwpCMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/view/[id].vue?macro=true";
import { default as callbackAazzpFl1zxMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/qb/callback.vue?macro=true";
import { default as logoutjJvTcPgH99Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/redirect/logout.vue?macro=true";
import { default as indexdTZUlWLxQcMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/index.vue?macro=true";
import { default as _91token_935nxNXWYkg6Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/[token].vue?macro=true";
import { default as _91token_93Pc7OfEY4joMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue?macro=true";
import { default as _91code_93VpIDkyHsPPMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/[code].vue?macro=true";
import { default as indexpXu26nxLl6Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/index.vue?macro=true";
import { default as _91code_93J0Ao7PRBdXMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue?macro=true";
import { default as _91code_939iqw7lzli5Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/view/[code].vue?macro=true";
import { default as ticketsEdDOhPYfvRMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets.vue?macro=true";
import { default as vote2yDqcQiSzpMeta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/vote.vue?macro=true";
import { default as component_45stubkKJPTBVny9Meta } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubkKJPTBVny9 } from "/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-lookup",
    path: "/account-lookup",
    meta: account_45lookup5NRB1ERAa0Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/account-lookup.vue")
  },
  {
    name: companiesz3Rx9WdiU0Meta?.name,
    path: "/admin/companies",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies.vue"),
    children: [
  {
    name: "admin-companies-add",
    path: "add",
    meta: addqo1LVeHNT7Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/add.vue")
  },
  {
    name: "admin-companies-deleted",
    path: "deleted",
    meta: deletedAbTEqVWkAOMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/deleted.vue")
  },
  {
    name: "admin-companies-edit-id",
    path: "edit/:id()",
    meta: _91id_93Kz3kWxFLgHMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/edit/[id].vue")
  },
  {
    name: "admin-companies",
    path: "",
    meta: indexmW58RDDAR6Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/index.vue")
  },
  {
    name: "admin-companies-view-id",
    path: "view/:id()",
    meta: _91id_93s8XtRXqunyMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/companies/view/[id].vue")
  }
]
  },
  {
    name: "admin-company-suggestions-confirm-id",
    path: "/admin/company-suggestions/confirm/:id()",
    meta: _91id_933A5aKRCXExMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/confirm/[id].vue")
  },
  {
    name: "admin-company-suggestions",
    path: "/admin/company-suggestions",
    meta: indexNb3bTj13BJMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/company-suggestions/index.vue")
  },
  {
    name: "admin-configs",
    path: "/admin/configs",
    meta: indexPIqh9OCrrNMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/configs/index.vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    meta: dashboardZQQzosrgl7Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/dashboard.vue")
  },
  {
    name: "admin-events-add",
    path: "/admin/events/add",
    meta: addgN4xYhbdLfMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/add.vue")
  },
  {
    name: "admin-events-appeal-id",
    path: "/admin/events/appeal/:id()",
    meta: _91id_93iGevfQoCisMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/appeal/[id].vue")
  },
  {
    name: "admin-events-edit-id",
    path: "/admin/events/edit/:id()",
    meta: _91id_93m2kUexOIgXMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/edit/[id].vue")
  },
  {
    name: "admin-events",
    path: "/admin/events",
    meta: indexIa6DQL3ZDXMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/index.vue")
  },
  {
    name: "admin-events-view-id",
    path: "/admin/events/view/:id()",
    meta: _91id_93XAO4agJq7eMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/events/view/[id].vue")
  },
  {
    name: "admin-judges",
    path: "/admin/judges",
    meta: index5kOzs9Az6oMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/judges/index.vue")
  },
  {
    name: logsDLf27niOxmMeta?.name,
    path: "/admin/logs",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs.vue"),
    children: [
  {
    name: "admin-logs-card-scan",
    path: "card-scan",
    meta: indexHM19tsF3tpMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/card-scan/index.vue")
  },
  {
    name: "admin-logs-email",
    path: "email",
    meta: indexh0zZ3H1cxzMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/email/index.vue")
  },
  {
    name: "admin-logs",
    path: "",
    meta: indexSpfdua1LWkMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/index.vue")
  },
  {
    name: "admin-logs-mailchimp",
    path: "mailchimp",
    meta: indexIZOmLyx4AIMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/mailchimp/index.vue")
  },
  {
    name: "admin-logs-payment",
    path: "payment",
    meta: indexBejNWUR0ahMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/logs/payment/index.vue")
  }
]
  },
  {
    name: members7hB1YPvp1QMeta?.name,
    path: "/admin/members",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members.vue"),
    children: [
  {
    name: "admin-members-add",
    path: "add",
    meta: adddSWw7Mt7zQMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/add.vue")
  },
  {
    name: "admin-members-indee-active-members",
    path: "indee-active-members",
    meta: indee_45active_45membersGF7Rtnlt0mMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/indee-active-members.vue")
  },
  {
    name: "admin-members",
    path: "",
    meta: indexUL5RggZ71UMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/index.vue")
  },
  {
    name: "admin-members-merge-id",
    path: "merge/:id()",
    meta: index6KgDBe7M5cMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/index.vue")
  },
  {
    name: "admin-members-merge-id-with-withId",
    path: "merge/:id()/with/:withId()",
    meta: _91withId_932MqCRRSG6bMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/[id]/with/[withId].vue")
  },
  {
    name: "admin-members-merge",
    path: "merge",
    meta: indextfR2cgGnjyMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/index.vue")
  },
  {
    name: "admin-members-merge-resolve-id",
    path: "merge/resolve/:id()",
    meta: _91id_931yYjIvEam3Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/merge/resolve/[id].vue")
  },
  {
    name: "admin-members-resend-welcome-email",
    path: "resend-welcome-email",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/resend-welcome-email.vue")
  },
  {
    name: "admin-members-screener-lists",
    path: "screener-lists",
    meta: screener_45listsPeH7p9AvwiMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/screener-lists.vue")
  },
  {
    name: "admin-members-student-applications",
    path: "student-applications",
    meta: indexdKiRjLnLSxMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/index.vue")
  },
  {
    name: "admin-members-student-applications-view-id",
    path: "student-applications/view/:id()",
    meta: _91id_93hzMFyxFRpNMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/members/student-applications/view/[id].vue")
  }
]
  },
  {
    name: "admin-memberships-statistics",
    path: "/admin/memberships/statistics",
    meta: statisticsJdutH7qr4OMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/statistics.vue")
  },
  {
    name: "admin-memberships-update-id",
    path: "/admin/memberships/update/:id()",
    meta: _91id_93qSCBN2PZ6wMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/memberships/update/[id].vue")
  },
  {
    name: "admin-payments",
    path: "/admin/payments",
    meta: indexo1PYWrbmHiMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/payments/index.vue")
  },
  {
    name: promo_45codetKYiowwcMNMeta?.name,
    path: "/admin/promo-code",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code.vue"),
    children: [
  {
    name: "admin-promo-code-add",
    path: "add",
    meta: addMl0XYA1WUDMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/add.vue")
  },
  {
    name: "admin-promo-code-edit-id",
    path: "edit/:id()",
    meta: _91id_93C1Gq96CwC8Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/edit/[id].vue")
  },
  {
    name: "admin-promo-code",
    path: "",
    meta: indexv3oomIdDT2Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/index.vue")
  },
  {
    name: "admin-promo-code-view-id",
    path: "view/:id()",
    meta: _91id_93KJc4gNPTbJMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/promo-code/view/[id].vue")
  }
]
  },
  {
    name: "admin-screenings",
    path: "/admin/screenings",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/screenings.vue")
  },
  {
    name: ticketsznfWSuR92CMeta?.name,
    path: "/admin/tickets",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets.vue"),
    children: [
  {
    name: "admin-tickets",
    path: "",
    meta: indexQHfY1uRCGDMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/index.vue")
  },
  {
    name: "admin-tickets-invite",
    path: "invite",
    meta: inviteOI8tk1XcWpMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/invite.vue")
  },
  {
    name: "admin-tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_93efuQtC7BlWMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/tickets/receipt/[code].vue")
  }
]
  },
  {
    name: "admin-voter-qualifications",
    path: "/admin/voter-qualifications",
    meta: indexG2C9RfBDRKMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/admin/voter-qualifications/index.vue")
  },
  {
    name: "companies",
    path: "/companies",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies.vue"),
    children: [
  {
    name: "companies-manager-dashboard",
    path: "manager/dashboard",
    meta: dashboardeu89o9yQkFMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/dashboard.vue")
  },
  {
    name: "companies-manager-view-id",
    path: "manager/view/:id()",
    meta: indexJNE9yN58PqMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/index.vue")
  },
  {
    name: "companies-manager-view-id-invoice-invoiceId",
    path: "manager/view/:id()/invoice/:invoiceId()",
    meta: _91invoiceId_93kKhRrZnTkSMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/invoice/[invoiceId].vue")
  },
  {
    name: "companies-manager-view-id-member-add",
    path: "manager/view/:id()/member/add",
    meta: addNzB6THnj1KMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/add.vue")
  },
  {
    name: "companies-manager-view-id-member-multiple-add",
    path: "manager/view/:id()/member/multiple-add",
    meta: multiple_45addZX9eYsHXKAMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/multiple-add.vue")
  },
  {
    name: "companies-manager-view-id-member-resolve-similar",
    path: "manager/view/:id()/member/resolve-similar",
    meta: index1vfk5JUpAaMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/companies/manager/view/[id]/member/resolve-similar/index.vue")
  }
]
  },
  {
    name: "events-screening-id",
    path: "/events/screening/:id()",
    meta: indexfhZSSfUcwHMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/events/screening/[id]/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordxwjGW0tpm0Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexr7IKF0UPPSMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/index.vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/invoices.vue")
  },
  {
    name: "judges-apply",
    path: "/judges/apply",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/judges/apply.vue")
  },
  {
    name: "members",
    path: "/members",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members.vue"),
    children: [
  {
    name: "members-addresses-id",
    path: "addresses/:id(.*)*",
    meta: _91_46_46_46id_93pVbYnIkub5Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/addresses/[...id].vue")
  },
  {
    name: "members-edit-id",
    path: "edit/:id()",
    meta: _91id_931E4NMVDFDkMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/edit/[id].vue")
  },
  {
    name: "members-membership",
    path: "membership",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/membership/index.vue")
  },
  {
    name: "members-signup",
    path: "signup",
    meta: indexKOa3Iu506cMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/signup/index.vue")
  },
  {
    name: "members-view-id",
    path: "view/:id(.*)*",
    meta: _91_46_46_46id_93WliK3sP37pMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/members/view/[...id].vue")
  }
]
  },
  {
    name: "memberships-card",
    path: "/memberships/card",
    meta: indexL36YH2EGr2Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/index.vue")
  },
  {
    name: "memberships-card-print-id",
    path: "/memberships/card/print/:id(.*)*",
    meta: _91_46_46_46id_93T81f0bmh5zMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/card/print/[...id].vue")
  },
  {
    name: "memberships-invoice-id",
    path: "/memberships/invoice/:id()",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/invoice/[id].vue")
  },
  {
    name: "memberships-my-membership",
    path: "/memberships/my-membership",
    meta: indexGksGhy5UeQMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/my-membership/index.vue")
  },
  {
    name: "memberships-update-id",
    path: "/memberships/update/:id()",
    meta: _91id_938mihhgZ6aWMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/[id].vue")
  },
  {
    name: "memberships-update-invoice-invoiceId",
    path: "/memberships/update/invoice/:invoiceId()",
    meta: _91invoiceId_93vV47Gsf6CTMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/memberships/update/invoice/[invoiceId].vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: password_45resetRnz6A4BlylMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/password-reset.vue")
  },
  {
    name: "payments",
    path: "/payments",
    meta: indexH618k8bOuiMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/index.vue")
  },
  {
    name: "payments-invoice-id",
    path: "/payments/invoice/:id()",
    meta: _91id_93cBPgadeClwMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/invoice/[id].vue")
  },
  {
    name: "payments-receipt-id",
    path: "/payments/receipt/:id()",
    meta: _91id_931ZvcBevkY6Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/receipt/[id].vue")
  },
  {
    name: "payments-view-id",
    path: "/payments/view/:id()",
    meta: _91id_93ubO6UkxwpCMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/payments/view/[id].vue")
  },
  {
    name: "qb-callback",
    path: "/qb/callback",
    meta: callbackAazzpFl1zxMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/qb/callback.vue")
  },
  {
    name: "redirect-logout",
    path: "/redirect/logout",
    meta: logoutjJvTcPgH99Meta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/redirect/logout.vue")
  },
  {
    name: ticketsEdDOhPYfvRMeta?.name,
    path: "/tickets",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets.vue"),
    children: [
  {
    name: "tickets",
    path: "",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/index.vue")
  },
  {
    name: "tickets-invite-token",
    path: "invite/:token()",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/[token].vue")
  },
  {
    name: "tickets-invite-payment-token",
    path: "invite/payment/:token()",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/invite/payment/[token].vue")
  },
  {
    name: "tickets-payment-code",
    path: "payment/:code()",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/[code].vue")
  },
  {
    name: "tickets-payment",
    path: "payment",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/payment/index.vue")
  },
  {
    name: "tickets-receipt-code",
    path: "receipt/:code()",
    meta: _91code_93J0Ao7PRBdXMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/receipt/[code].vue")
  },
  {
    name: "tickets-view-code",
    path: "view/:code()",
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/tickets/view/[code].vue")
  }
]
  },
  {
    name: "vote",
    path: "/vote",
    meta: vote2yDqcQiSzpMeta || {},
    component: () => import("/tmp/codebuild/output/src779/src/codestar-connections.us-east-1.amazonaws.com/git-http/694641143822/us-east-1/4b6391fa-1087-45ce-af46-a82a71745ba3/clearview/asifa-members-fe/pages/vote.vue")
  },
  {
    name: component_45stubkKJPTBVny9Meta?.name,
    path: "/judge-application",
    component: component_45stubkKJPTBVny9
  },
  {
    name: component_45stubkKJPTBVny9Meta?.name,
    path: "/profile",
    component: component_45stubkKJPTBVny9
  },
  {
    name: component_45stubkKJPTBVny9Meta?.name,
    path: "/login",
    component: component_45stubkKJPTBVny9
  },
  {
    name: component_45stubkKJPTBVny9Meta?.name,
    path: "/join",
    component: component_45stubkKJPTBVny9
  },
  {
    name: component_45stubkKJPTBVny9Meta?.name,
    path: "/memberships/my_membership",
    component: component_45stubkKJPTBVny9
  }
]